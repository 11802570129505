/* eslint-disable import/no-extraneous-dependencies, global-require */
const defaultTheme = require('tailwindcss/defaultTheme');

// See https://tailwindcss.com/docs/configuration for details
module.exports = {
  mode: 'jit',
  purge: ['./src/**/*.{js,jsx,ts,tsx}'],
  darkMode: false, // or 'media' or 'class'
  theme: {
    typography: (theme) => ({
      default: {
        css: {
          a: {
            color: theme('colors.gray.900'),
            textDecoration: 'underline',
          },
        },
      },
    }),
    extend: {
      cursor: {
        ...defaultTheme.cursor,
        'zoom-in': 'zoom-in',
      },
      fontFamily: {
        sans: ['Raleway', ...defaultTheme.fontFamily.sans],
        serif: [
          'Big Caslon',
          'Libre Caslon',
          'Book Antiqua',
          'Palatino Linotype',
          'Georgia',
          'Cambria',
          'Times New Roman',
          'Times',
          'serif',
        ],
      },
      colors: {
        'transparent-black': {
          25: 'hsla(0, 0%, 0%, 0.25)',
          50: 'hsla(0, 0%, 0%, 0.50)',
          75: 'hsla(0, 0%, 0%, 0.75)',
        },
        'transparent-white': {
          25: 'hsla(0, 100%, 100%, 0.25)',
          50: 'hsla(0, 100%, 100%, 0.50)',
          75: 'hsla(0, 100%, 100%, 0.75)',
        },
        pink: {
          DEFAULT: '#d5afb0',
        },
        gray: {
          50: '#fbfbfb',
          100: '#f7f7f7',
          200: '#ebebeb',
          300: '#dcdcdc',
          400: '#b2b2b2',
          500: '#808080',
          600: '#636363',
          700: '#515151',
          800: '#3f3f3f',
          900: '#2e2e2e',
        },
        red: '#a50042',
      },
    },
  },
  // Default values here: https://tailwindcss.com/docs/pseudo-class-variants#default-variants-reference
  variants: {
    backgroundColor: ['responsive', 'focus', 'odd', 'hover'],
    borderWidth: ['responsive', 'last'],
    cursor: ['responsive', 'disabled'],
    margin: ['responsive', 'first'],
    opacity: ['responsive', 'hover', 'focus', 'disabled'],
    scale: ['responsive', 'hover', 'group-focus', 'group-hover'],
  },
  plugins: [
    require('@tailwindcss/forms'),
    require('@tailwindcss/typography'),
    require('@tailwindcss/aspect-ratio'),
    require('@tailwindcss/line-clamp'),
  ],
};
