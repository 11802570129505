import { navigate } from 'gatsby';
import React, { useState } from 'react';

import { Input, TextArea } from './form';

function encode(data) {
  return Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');
}

export function ContactForm() {
  const [state, setState] = useState({
    name: '',
    email: '',
    tel: '',
    message: '',
  });

  const handleChange = (e) =>
    setState({ ...state, [e.target.name]: e.target.value });

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      // eslint-disable-next-line no-alert
      .catch((error) => alert(error));
  };

  return (
    <div className="relative flex flex-col flex-1 w-full px-4 py-8 border">
      <h2 className="mb-6 font-serif text-2xl font-semibold leading-tight text-center uppercase">
        Get in touch
      </h2>
      <form
        action="/success/"
        className="flex flex-col flex-1 w-full"
        data-netlify-honeypot="bot-field"
        data-netlify="true"
        method="post"
        name="contact"
        onSubmit={handleSubmit}
      >
        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
        <input name="form-name" type="hidden" value="contact" />
        <div hidden>
          <label htmlFor="bot-field">
            Don’t fill this out:{' '}
            <input id="bot-field" name="bot-field" onChange={handleChange} />
          </label>
        </div>

        {/* Name */}
        <Input
          handleChange={handleChange}
          label="Name"
          name="name"
          type="text"
        />

        {/* Email address */}
        <Input
          handleChange={handleChange}
          label="Email"
          name="email"
          type="email"
        />

        {/* Phone number */}
        <Input
          handleChange={handleChange}
          label="Phone number"
          name="tel"
          required={false}
          type="tel"
        />

        {/* Message */}
        <TextArea
          handleChange={handleChange}
          label="Message"
          name="message"
          rows={8}
        />

        {/* Submit */}
        <div className="flex justify-end">
          <button
            className="inline-block px-4 py-1 leading-none uppercase transition-all border border-gray-500 hover:bg-gray-800 hover:text-white hover:border-gray-800"
            type="submit"
          >
            Send
          </button>
        </div>
      </form>
    </div>
  );
}
