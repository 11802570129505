import * as React from 'react';
import { Carousel } from '../carousel';
import { HeroSlide } from './hero-slide';
import { OnlineShopSlide } from './online-shop-slide';
import { MothersDaySlide } from './mothers-day-slide';

export function Hero() {
  return (
<header className="aspect-w-16 aspect-h-9">
      <video
        playsInline
        autoPlay
        muted
        loop
        poster="https://res.cloudinary.com/dkj959jfx/image/upload/f_auto,q_auto/v1623294079/port-macquarie-day-spa/2021-port-day-spa-website-video-still-v1_ns24bz.jpg"
      >
        <source src="https://res.cloudinary.com/dkj959jfx/video/upload/f_auto/v1623287119/port-macquarie-day-spa/welcome-to-port-macquarie-day-spa_p1pky6.mp4" type="video/mp4" />
      </video>
    </header>
  );
}
