import * as React from 'react';
import LazyLoad from 'react-lazyload';

import { useGraphQL } from '../hooks';

export function Map() {
  const { site } = useGraphQL();

  return (
    <>
      <LazyLoad height={256} once>
        <iframe
          src={site.siteMetadata.googleMapsEmbed}
          allowFullScreen
          className="relative w-full mt-auto"
          frameBorder={0}
          title="map"
          height={256}
        />
      </LazyLoad>
    </>
  );
}
