import PropTypes from 'prop-types';
import * as React from 'react';

export function InstagramImage({ post }) {
  return (
    <a
      href={post.url}
      target="_blank"
      rel="noreferrer noopener"
      className="overflow-hidden aspect-h-1 aspect-w-1"
    >
      <div>
        <img
          src={`https://hidden-springs-13326.herokuapp.com/imgproxy.php?url=${encodeURIComponent(
            post.thumbnail_src
          )}`}
          alt={post.caption}
          loading="lazy"
          className="object-cover w-full h-full"
        />
      </div>
      <div className="flex flex-col overflow-y-auto opacity-0 hover:opacity-100">
        <div className="flex-1 p-4 font-sans font-light text-white break-words hover:bg-transparent-black-50 hover:blur">
          {post.caption}
        </div>
      </div>
    </a>
  );
}

InstagramImage.propTypes = {
  post: PropTypes.shape({
    caption: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
    srcSet: PropTypes.array.isRequired,
    url: PropTypes.string.isRequired,
  }),
};
