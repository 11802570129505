import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as React from 'react';

import { useGraphQL } from '../hooks';
import { Logo } from './logo';

export function Footer() {
  const { marbleBg, site } = useGraphQL();

  return (
    <footer className="relative w-full">
      <div className="absolute inset-0 overflow-hidden">
        <GatsbyImage
          image={marbleBg.childImageSharp.gatsbyImageData}
          className="h-full opacity-75"
          alt=""
        />
      </div>
      <div className="relative mx-auto">
        <div className="flex flex-wrap items-center justify-center w-full max-w-6xl px-4 py-12 mx-auto">
          <Link to="/" className="w-full md:w-1/2">
            <Logo className="w-full max-w-xs mx-auto fill-current" />
          </Link>
          <div className="w-full uppercase md:w-1/2">
            <p>
              <strong>Phone: </strong>
              <a href={`tel:${site.siteMetadata.phone}`}>
                {site.siteMetadata.phoneFormatted}
              </a>
            </p>
            <p className="mt-0">
              <strong>Address: </strong>
              <a
                href={site.siteMetadata.googleMapsLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                {site.siteMetadata.address}
              </a>
            </p>
            <p className="mt-0">
              <strong>Email: </strong>
              <a href={`mailto:${site.siteMetadata.email}`}>
                {site.siteMetadata.email}
              </a>
            </p>
            <p className="mt-0">
              <strong>ABN:</strong> {site.siteMetadata.abn}
            </p>
            {/* <p className="mt-0">
              <strong>
                <Link to="/covid-policy/">COVID Policy</Link>
              </strong>
            </p> */}
          </div>
        </div>
        <div className="flex flex-wrap items-center justify-center w-full p-4 mb-0 text-center">
          <span className="w-full flex-no-shrink md:w-auto">
            © {new Date().getFullYear()} {site.siteMetadata.title}
          </span>
          <span className="hidden w-full mx-2 md:block md:w-auto">|</span>
          <span className="w-full flex-no-shrink md:w-auto">
            <a
              className="inline-flex px-1 font-semibold"
              href="https://pd.design"
              rel="noopener noreferrer"
              target="_blank"
            >
              Designed and developed by PD
            </a>
          </span>
        </div>
      </div>
    </footer>
  );
}
