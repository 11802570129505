import PropTypes from 'prop-types';
import * as React from 'react';
import Headroom from 'react-headroom';

import { Nav } from './nav';
import { ShopBar } from './shop-bar';

export function Navbar({ isShop }) {
  return (
    <Headroom>
      <header
        className="sticky top-0 z-10 w-full leading-none bg-white bg-opacity-25 backdrop-blur-lg backdrop-filter"
      >
        <Nav />
        {isShop && <ShopBar />}
      </header>
    </Headroom>
  );
}

Navbar.propTypes = {
  isShop: PropTypes.bool,
};
