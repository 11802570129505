import * as React from 'react';

import { useGraphQL } from '../hooks';
import { Facebook, Instagram } from './vectors';

export function SocialWidgetDesktop() {
  const { site } = useGraphQL();
  return (
    <div className="fixed inset-y-0 left-0 z-10 flex flex-col justify-center flex-1 p-4 pointer-events-none">
      <div>
        <a
          href={site.siteMetadata.facebook}
          rel="noopener noreferrer"
          target="_blank"
        >
          <Facebook className="text-3xl text-gray-700 bg-white rounded-full pointer-events-auto fill-current hover:text-gray-600" />
        </a>
      </div>
      <div className="mt-2">
        <a
          href={site.siteMetadata.instagram}
          rel="noopener noreferrer"
          target="_blank"
        >
          <Instagram className="text-3xl text-gray-700 bg-white rounded-full pointer-events-auto fill-current hover:text-gray-600" />
        </a>
      </div>
    </div>
  );
}
