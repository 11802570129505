import PropTypes from 'prop-types';
import * as React from 'react';

export function Input({
  handleChange,
  label,
  name,
  required = true,
  type = 'text',
}) {
  return (
    <div className="mb-6">
      <label className="flex items-end" htmlFor={name}>
        <span className="sr-only">label</span>
        <input
          className="block w-full px-3 py-2 leading-tight text-gray-700 bg-white border border-gray-400 rounded-none appearance-none focus:bg-gray-100 focus:ring-blue-500 focus:ring-opacity-50 focus:ring-1"
          id={name}
          name={name}
          onChange={handleChange}
          placeholder={`${label}:`}
          required={required}
          type={type}
        />
      </label>
    </div>
  );
}

Input.propTypes = {
  handleChange: PropTypes.func,
  label: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
};
