import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as React from 'react';

import { useGraphQL } from '../hooks';
import { Parallax } from './parallax';

export function ServicesSection() {
  const { servicesHero } = useGraphQL();
  return (
    <>
      <div className="flex items-center overflow-hidden">
        <div className="flex-1">
          <Parallax>
            <GatsbyImage
              image={servicesHero.childImageSharp.gatsbyImageData}
              className="md:-my-12"
              alt=""
            />
          </Parallax>
        </div>
      </div>
      <article
        id="services"
        className="relative z-10 w-full px-4 py-24 bg-white sm:px-6 lg:px-8"
      >
        <div className="flex flex-wrap w-full max-w-6xl mx-auto">
          <div className="flex items-center justify-center w-full md:w-2/5">
            <h2 className="w-full font-serif text-2xl font-semibold leading-tight uppercase md:p-10">
              <span className="inline-block">Port Macquarie&nbsp;</span>
              <div className="inline-block">Day Spa&nbsp;</div>
              Services
            </h2>
          </div>
          <div className="w-full md:col-2 lg:col-3 md:w-3/5">
            <ul className="pr-12 font-bold leading-none">
              <li className="mt-4 md:mt-0">Skin Consultations</li>
              <li className="mt-4">Signature Facials</li>
              <li className="mt-4">Skin Workouts</li>
              <li className="mt-4">Skin Needling</li>
              <li className="mt-4">
                Paramedical<span>&nbsp;</span>Treatments
              </li>
              <li className="mt-4">Microdermabrasion</li>
              <li className="mt-4">IPL/ SHR</li>
              <li className="mt-4">Spa Packages</li>
              <li className="mt-4">Massage</li>
              <li className="mt-4">Waxing &amp; Tinting</li>
              <li className="mt-4">Lash Lifting</li>
              {/* <li className="mt-4">Makeup</li> */}
              {/* <li className="mt-4">Manicures &amp; Pedicures</li> */}
            </ul>
          </div>
          <div className="w-full mt-8 ml-auto md:w-3/5">
            <Link
              to="/services"
              className="inline-block px-2 py-1 leading-none uppercase transition-all border border-gray-500 hover:bg-gray-800 hover:text-white hover:border-gray-800"
            >
              View more information here
            </Link>
          </div>
        </div>
      </article>
    </>
  );
}
