import PropTypes from 'prop-types';
import * as React from 'react';
import Media from 'react-media';

import { COVID19Message } from './covid-19-message';
import { Footer } from './footer';
import { Navbar } from './navbar';
import { SocialWidgetDesktop } from './social-widget-desktop';
import { MothersDayBanner } from './mothers-day-banner';

export function Layout({ children, isShop }) {
  return (
    <div className="relative flex flex-col min-h-screen font-sans antialiased leading-relaxed text-gray-700">
      {/* <MothersDayBanner/> */}
      <Navbar isShop={isShop} />
      <main className="relative z-0 flex flex-col flex-1 w-full mx-auto bg-white">
        {children}
      </main>
      <Footer />
      <Media query="(min-width: 1280px)">
        {(matches) => (matches ? <SocialWidgetDesktop /> : null)}
      </Media>
      {/* <COVID19Message /> */}
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node,
  isShop: PropTypes.bool,
};
