import { DialogContent, DialogOverlay } from '@reach/dialog';
import { AnimatePresence, motion } from 'framer-motion';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FiSearch, FiX } from 'react-icons/fi';

import { useGraphQL } from '../hooks/use-graphql';
import { resizeShopifyImage } from '../utilities';

export function SearchModal({ isModalOpen, setIsModalOpen }) {
  const {
    allShopifyProduct: { nodes: products },
  } = useGraphQL();

  const closeModal = () => setIsModalOpen(false);

  // Add state for query
  const [query, setQuery] = useState('');

  // Add state for search results
  const [searchResults, setSearchResults] = useState([]);

  // When query changes, update the state
  function handleChange(e) {
    setQuery(e.target.value);
  }

  useEffect(() => {
    // Whenever query is not an empty string, update the productResults
    if (query !== '') {
      const results = products.filter((product) =>
        product.title.toLowerCase().includes(query.toLowerCase())
      );
      setSearchResults(results);
    }
    // If the user starts typing, and then deletes their query, set it back to an empty array
    else setSearchResults([]);
  }, [products, query]);

  return (
    <AnimatePresence>
      {isModalOpen ? (
        <DialogOverlay
          isOpen={isModalOpen}
          onDismiss={closeModal}
          as={motion.div}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-x-0 top-0 z-50 flex w-full h-full min-h-screen overflow-auto bg-transparent-white-50 backdrop-filter backdrop-blur"
        >
          <DialogContent
            as={motion.div}
            aria-label="Search"
            initial={{ opacity: 0, y: '-1rem' }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: '-1rem' }}
            transition={{ duration: 0.15 }}
            className="fixed top-0 items-center justify-center w-full px-4 py-16 m-0 text-center text-gray-700 bg-white border outline-none"
          >
            <div className="flex w-full max-w-3xl mx-auto">
              <div className="relative w-full">
                <label htmlFor="search" className="flex-1 inline-block w-full">
                  <span className="sr-only">Search</span>

                  <div className="relative shadow-sm">
                    <input
                      onChange={handleChange}
                      value={query}
                      placeholder="Search"
                      id="search"
                      type="text"
                      className="block w-full pr-10 rounded-none sm:text-sm sm:leading-5 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                    />
                    <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                      <FiSearch className="w-5 h-5 text-gray-500" />
                    </div>
                  </div>
                </label>
                {searchResults.length > 0 && (
                  <div className="absolute inset-x-0 z-10 p-4 text-left bg-white border top-9">
                    <h3 className="px-4 pb-4 uppercase">Products</h3>
                    <ul className="border-t">
                      {searchResults.slice(0, 10).map((result) => (
                        <li
                          key={result.handle}
                          className="-mx-4 hover:bg-gray-200 odd:bg-gray-50"
                        >
                          <Link
                            to={`/products/${result.handle}`}
                            className="flex items-center px-8 py-2"
                          >
                            <img
                              src={
                                result.images[0]
                                  ? resizeShopifyImage({
                                      url: result.images[0].originalSrc,
                                      width: 44,
                                    })
                                  : ''
                              }
                              alt=""
                              width={44}
                              height={44}
                              className="object-contain bg-white h-11 w-11"
                            />
                            <span className="ml-2">{result.title}</span>
                          </Link>
                        </li>
                      ))}
                    </ul>
                    <div className="px-4 pt-4 border-t">
                      Searching for: "{query}"
                    </div>
                  </div>
                )}
              </div>
              <button
                type="button"
                onClick={closeModal}
                className="ml-4 text-2xl"
              >
                <FiX />
              </button>
            </div>
          </DialogContent>
        </DialogOverlay>
      ) : null}
    </AnimatePresence>
  );
}

SearchModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
};
