import { GatsbyImage } from 'gatsby-plugin-image';
import * as React from 'react';

import { useGraphQL } from '../hooks';
import { GiftVoucherCopy } from './gift-voucher-copy';
import { Parallax } from './parallax';

export function GiftVoucher() {
  const { giftVoucherHero } = useGraphQL();
  return (
    <div className="relative z-10">
      <div className="flex items-center overflow-hidden">
        <div className="flex-1">
          <Parallax>
            <GatsbyImage
              image={giftVoucherHero.childImageSharp.gatsbyImageData}
              className="md:-my-12"
              alt=""
            />
          </Parallax>
        </div>
      </div>
      <GiftVoucherCopy />
    </div>
  );
}
