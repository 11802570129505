import PropTypes from 'prop-types';
import * as React from 'react';

export function TextArea({
  handleChange,
  label,
  name,
  required = true,
  rows = 8,
}) {
  return (
    <div className="flex flex-1 mb-6">
      <label className="flex w-full" htmlFor={name}>
        <span className="sr-only">{label}</span>
        <textarea
          className="block w-full px-3 py-2 leading-tight text-gray-700 bg-white border border-gray-400 rounded-none appearance-none focus:bg-gray-100 focus:ring-blue-500 focus:ring-opacity-50 focus:ring-1"
          id={name}
          name={name}
          placeholder={`${label}:`}
          rows={rows}
          onChange={handleChange}
          required={required}
        />
      </label>
    </div>
  );
}

TextArea.propTypes = {
  handleChange: PropTypes.func,
  label: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  rows: PropTypes.number,
};
