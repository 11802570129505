import PropTypes from 'prop-types';
import * as React from 'react';

import { useInstagram } from '../../hooks';
import { Spinner } from '../spinner';
import { InstagramImage } from './instagram-image';

export function InstagramWidget({ imagesToShow = 6 }) {
  const posts = useInstagram();

  return (
    <article className="px-4 auto">
      <div className="max-w-2xl my-4 -mx-6 md:mb-0 md:mt-12">
        <div className="grid grid-cols-3 gap-4 md:pr-2">
          {posts.length > 0
            ? posts
                .slice(0, imagesToShow)
                .map((post) => <InstagramImage key={post.id} post={post} />)
            : new Array(imagesToShow).fill('').map((_, i) => (
                <div
                  key={i}
                  className="border bg-gray-50 aspect-h-1 aspect-w-1"
                >
                  <div className="flex items-center justify-center w-full h-full">
                    <Spinner />
                  </div>
                </div>
              ))}
        </div>
      </div>
    </article>
  );
}

InstagramWidget.propTypes = {
  imagesToShow: PropTypes.number,
};
