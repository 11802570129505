import { graphql, useStaticQuery } from 'gatsby';

export function useGraphQL() {
  return useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          abn
          address
          email
          facebook
          googleMapsEmbed
          googleMapsLink
          instagram
          phone
          phoneFormatted
          title
        }
      }
      mobileHeroFirstImage: file(relativePath: { eq: "hero-mobile-1.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      desktopHeroFirstImage: file(relativePath: { eq: "hero-1.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      mobileHeroSecondImage: file(relativePath: { eq: "hero-mobile-2.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      desktopHeroSecondImage: file(relativePath: { eq: "hero-2.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      mobileHeroThirdImage: file(relativePath: { eq: "hero-mobile-3.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      desktopHeroThirdImage: file(relativePath: { eq: "hero-3.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      marbleBg: file(relativePath: { eq: "marble.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      contactHero: file(relativePath: { eq: "contact-hero.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      giftVoucherHero: file(relativePath: { eq: "gift-voucher.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      voucherDesktop: file(relativePath: { eq: "gift-card-desktop.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      voucherMobile: file(relativePath: { eq: "gift-card-mobile.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      servicesHero: file(relativePath: { eq: "services-2.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      storeHero: file(relativePath: { eq: "store/hero.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      goodSkinStartsHere: file(
        relativePath: { eq: "store/vita-sol-purity-wholefood.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      ANPImage: file(relativePath: { eq: "store/anp.jpeg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      mothersDayImage: file(relativePath: { eq: "store/mothers-day.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      ultraceuticalsImage: file(
        relativePath: { eq: "store/ultraceuticals.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      placeholderImage: file(relativePath: { eq: "placeholder.jpg" }) {
        publicURL
      }
    }
  `);
}
