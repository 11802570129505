import { Dialog, Transition, Popover } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { useLocation } from '@reach/router';
import { Link } from 'gatsby';
import * as React from 'react';
import { usePopper } from 'react-popper';
import { AnimatePresence, motion } from 'framer-motion';

import { useGraphQL } from '../hooks';
import { classNames } from '../utilities/classnames';
import { Logo } from './logo';
import { ShopMenu } from './shop-menu';

export function Nav() {
  const navigation = [
    { name: 'Skin', href: '/services/skin/' },
    {
      name: 'Spa',
      href: '/services/spa-packages/',
      subMenu: [
        {
          name: 'Spa Journeys',
          href: '/services/spa-packages/',
        },
        {
          name: 'Massage',
          href: '/services/body/',
        },
      ],
    },
    { name: 'Beauty', href: '/services/beauty/' },
    {
      name: 'Shop',
      href: '/shop/',
      subMenu: [
        {
          name: 'Shop All',
          href: 'https://shop-us.phorest.com/businesses/WfUmCEGDtCCpF42WP5Z7PQ/branches/8KfHICGiE6iGTGZXu8xbwA/products',
        },
        {
          name: 'Ultraceuticals',
          href: 'https://www.ultraceuticals.com/au/category/all-products.html?ref=portm',
        },
      ],
    },
    { name: 'Voucher', href: '/gift-voucher/' },
    { name: 'About', href: '/why/' },
    { name: 'Contact', href: '/contact/' },
  ];

  const [isOpen, setIsOpen] = React.useState(false);
  const { site } = useGraphQL();
  const { pathname } = useLocation();
  return (
    <header className="relative z-30 backdrop-filter backdrop-blur">
      <div className="px-2 mx-auto max-w-7xl sm:px-4 lg:px-8">
        <div className="relative flex justify-between h-20 py-4">
          <div className="relative z-10 hidden px-2 lg:px-0 sm:flex">
            <div className="flex items-center flex-shrink-0">
              <a
                href={`tel:${site.siteMetadata.phone}`}
                rel="noopener noreferrer"
                target="_blank"
                className="inline-flex items-center px-4 space-x-2 text-base font-light leading-6 text-gray-500 uppercase transition duration-150 ease-in-out border border-gray-400 group hover:text-white hover:bg-gray-800 hover:border-gray-800 focus:outline-none focus:text-white focus:border-gray-800 focus:bg-gray-800 active:bg-gray-900"
              >
                <span>Call: {site.siteMetadata.phoneFormatted}</span>
              </a>
            </div>
          </div>
          <div className="relative z-0 flex items-center flex-1 px-2 sm:justify-center sm:absolute sm:inset-0">
            <div className="inline-flex w-full sm:justify-center sm:max-w-xs">
              <Link
                to="/"
                className="text-gray-600 hover:text-gray-900 focus:text-gray-900"
              >
                <Logo className="h-12 fill-current" />
              </Link>
            </div>
          </div>
          <div className="relative z-10 flex items-center lg:hidden">
            {/* Mobile menu button */}
            <button
              type="button"
              onClick={() => setIsOpen(true)}
              className="inline-flex items-center justify-center p-2 text-gray-400 rounded-md hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset"
            >
              <span className="sr-only">Open menu</span>
              {isOpen ? (
                <XIcon className="block w-6 h-6" aria-hidden />
              ) : (
                <MenuIcon className="block w-6 h-6" aria-hidden />
              )}
            </button>
          </div>
          <div className="hidden lg:relative lg:z-10 lg:ml-4 lg:flex lg:items-center">
            <a
              href="https://phorest.com/book/salons/portmacquariedayspa"
              rel="noopener noreferrer"
              target="_blank"
              className="inline-flex items-center px-4 space-x-2 text-base font-light leading-6 text-gray-500 uppercase transition duration-150 ease-in-out border border-gray-400 group hover:text-white hover:bg-gray-800 hover:border-gray-800 focus:outline-none focus:text-white focus:border-gray-800 focus:bg-gray-800 active:bg-gray-900"
            >
              <span>Booking</span>
            </a>
          </div>
        </div>
        <nav
          className="hidden lg:py-2 lg:flex lg:space-x-6 lg:justify-center"
          aria-label="Global"
        >
          {navigation.map((item) =>
            item.subMenu ? (
              <DropDown navItem={item} key={item.name} />
            ) : (
              <Link
                key={item.name}
                to={item.href}
                className={classNames(
                  item.href === pathname
                    ? 'bg-gray-50 text-gray-900'
                    : 'text-gray-900 hover:bg-gray-50 hover:text-gray-900',
                  'items-center block px-3 py-2 space-x-2 text-base font-light leading-6 text-gray-500 uppercase transition duration-150 ease-in-out rounded-md group hover:text-gray-900 focus:outline-none focus:text-gray-900'
                )}
              >
                {item.name}
              </Link>
            )
          )}
        </nav>
      </div>
      <MobileMenu isOpen={isOpen} setIsOpen={setIsOpen} />
    </header>
  );
}

function DropDown({ navItem }) {
  const [referenceElement, setReferenceElement] = React.useState();
  const [popperElement, setPopperElement] = React.useState();
  const { styles, attributes } = usePopper(referenceElement, popperElement);
  const { pathname } = useLocation();

  return (
    <Popover>
      {({ open }) => (
        <>
          <Popover.Button
            ref={setReferenceElement}
            className={classNames(
              navItem?.href === pathname
                ? 'bg-gray-50 text-gray-900'
                : 'text-gray-900 hover:bg-gray-50 hover:text-gray-900',
              'items-center block px-3 py-2 space-x-2 text-base font-light leading-6 text-gray-500 uppercase transition duration-150 ease-in-out rounded-md group hover:text-gray-900 focus:outline-none focus:text-gray-900 flex items-center'
            )}
          >
            <span>{navItem?.name}</span>
            <svg
              className={`${
                open ? 'text-gray-600' : 'text-gray-400'
              } w-5 h-5 transition duration-150 ease-in-out group-hover:text-gray-500 group-focus:text-gray-500`}
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </Popover.Button>
          <AnimatePresence>
            {open ? (
              <Popover.Panel
                ref={setPopperElement}
                as={motion.div}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.15 }}
                static
                className="mt-3"
                style={styles.popper}
                {...attributes.popper}
              >
                <motion.div
                  initial={{ y: '-1rem' }}
                  animate={{ y: 0 }}
                  exit={{ y: '-1rem' }}
                  transition={{ duration: 0.15 }}
                  className="rounded-lg shadow-lg"
                >
                  <div className="z-30 rounded-lg ring-1 ring-black ring-opacity-5">
                    <nav className="p-2 space-y-2 bg-white">
                      {navItem.subMenu.map((subMenu) => (
                        <Link
                          key={subMenu.name}
                          to={subMenu.href}
                          className="flex items-center block p-2 text-gray-500 transition duration-150 ease-in-out rounded-none hover:bg-gray-50 focus:outline-none focus:bg-gray-100"
                        >
                          <div className="text-base font-light leading-6 text-gray-900 uppercase">
                            {subMenu.name}
                          </div>
                        </Link>
                      ))}
                    </nav>
                  </div>
                </motion.div>
              </Popover.Panel>
            ) : null}
          </AnimatePresence>
        </>
      )}
    </Popover>
  );
}

function MobileMenu({ isOpen, setIsOpen }) {
  const navigation = [
    { name: 'Skin', href: '/services/skin/' },
    {
      name: 'Spa',
      href: '/services/spa-packages/',
    },
    {
      name: 'Massage',
      href: '/services/body/',
    },
    { name: 'Beauty', href: '/services/beauty/' },
    { name: 'Shop All', href: 'https://shop-us.phorest.com/businesses/WfUmCEGDtCCpF42WP5Z7PQ/branches/8KfHICGiE6iGTGZXu8xbwA/products' },
    { name: 'Ultraceuticals', href: 'https://www.ultraceuticals.com/au/category/all-products.html?ref=portm'},
    { name: 'Voucher', href: '/gift-voucher/' },
    { name: 'About', href: '/why/' },
    { name: 'Contact', href: '/contact/' },
  ];

  const { pathname } = useLocation();
  return (
    <Transition.Root show={isOpen} as={React.Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-10 overflow-y-auto"
        open={isOpen}
        onClose={setIsOpen}
      >
        <div className="flex items-start justify-center min-h-screen px-4 pt-4 pb-20 text-center">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden
          >
            &#8203;
          </span>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative z-10 w-full shadow-lg">
              <div className="bg-white divide-y-2 rounded-none ring-1 ring-black ring-opacity-5 divide-gray-50">
                <div className="px-5 pt-5 pb-6 space-y-6">
                  <div className="flex items-center justify-between">
                    <Link
                      to="/"
                      className="text-gray-600 hover:text-gray-900 focus:outline-none focus:text-gray-900"
                    >
                      <Logo className="w-auto h-8 fill-current" />
                    </Link>
                    <div className="-mr-2">
                      <button
                        type="button"
                        onClick={() => setIsOpen(false)}
                        className="inline-flex items-center justify-center p-2 text-gray-400 transition duration-150 ease-in-out rounded hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500"
                      >
                        <svg
                          className="w-6 h-6"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div>
                    <nav className="grid grid-cols-1 gap-7">
                      {navigation.map((navLink) => (
                        <Link
                          key={navLink.name}
                          to={navLink.href}
                          className={classNames(
                            pathname === navLink.href ? 'bg-gray-50' : '',
                            'flex items-center p-3 -m-3 space-x-4 text-gray-500 transition duration-150 ease-in-out rounded-none hover:bg-gray-100 focus:outline-none focus:bg-gray-200'
                          )}
                        >
                          <div className="text-base font-light leading-6 text-gray-900 uppercase">
                            {navLink.name}
                          </div>
                        </Link>
                      ))}
                    </nav>
                  </div>
                </div>
                {/* <ShopMenu /> */}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
