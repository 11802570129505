import { Link } from 'gatsby';
import * as React from 'react';

import { Afterpay } from './vectors';

export function Why() {
  return (
    <article
      id="why"
      className="relative z-10 w-full px-4 py-24 bg-white sm:px-6 lg:px-8"
    >
      <div className="flex flex-wrap w-full max-w-6xl mx-auto">
        <div className="flex items-center justify-center w-full md:w-1/3">
          <h2 className="w-full font-serif text-2xl font-semibold leading-tight uppercase md:p-10">
            <span className="inline-block">Why Choose&nbsp;</span>
            <span className="inline-block">Port Macquarie&nbsp;</span>
            <span className="inline-block">Day Spa?</span>
          </h2>
        </div>
        <div className="w-full md:col-2 md:w-2/3">
          <p className="mt-4 md:mt-0">
            <strong>
              Where tranquil relaxation meets unforgettable results
            </strong>
          </p>
          <p>
            Adjacent to the picturesque waters of the Hastings River, Port Day
            Spa transcends guests on a journey of self-love through their
            curated menu of skin, spa and beauty rituals.
          </p>
          <p>
            Our clinical-forward{' '}
            <span className="italic font-medium">skin menu</span> supports you
            to revive, condition and correct your skin concerns and deliver
            visible skin results. We harness industry leading cosmeceuticals and
            results-driven technology to craft tailored treatment journeys that
            achieve your individual skin goals at your chosen pace.
          </p>
          <p>
            Our luxurious{' '}
            <span className="italic font-medium">spa journeys</span> and
            indulgent body treatments are dedicated to helping you to pause,
            surrender and reconnect. From the warm embrace of our body wraps to
            enjoying a glass of bubbles in a grounding milk bath, our spa
            journeys are truly the quintessential Port Day Spa experience.
          </p>
          <p>
            Port Day Spa is also your home to your{' '}
            <span className="italic font-medium">beauty essentials</span>{' '}
            including waxing, tanning, brows, lashes and makeup. We’re your
            tanning specialists to leave your glowing inside and out.
          </p>
          <p>
            Our treatment rooms are beautifully presented with heated beds,
            tranquil lighting and soothing sounds. Our fresh, Hamptons-inspired
            retreat is perfect for the ultimate{' '}
            <span className="italic font-medium">‘me time’</span> moment or one
            shared with a loved one thanks to our double treatment rooms and
            state-of-the-art relaxation spa zone.
          </p>
          <p>
            All that’s left is for you to step through our doors, farewell the
            outside world for just a moment and surrender to total and utter
            relaxation.{' '}
            <span className="italic font-medium">
              We’ll take care of the rest.
            </span>
          </p>
          <p>
            <Link to="/why" className="font-bold underline">
              Read more...
            </Link>
          </p>
          <p>
            <Afterpay className="w-1/2 mx-auto" />
          </p>
        </div>
        <div className="w-full mx-auto mt-8 md:w-1/3">
          <a
            href="https://phorest.com/book/salons/portmacquariedayspa"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block px-2 py-1 leading-none uppercase transition-all border border-gray-500 hover:bg-gray-800 hover:text-white hover:border-gray-800"
          >
            Book now
          </a>
        </div>
      </div>
    </article>
  );
}
