/* eslint-disable react/jsx-props-no-spreading */
import {
  motion,
  useReducedMotion,
  useSpring,
  useTransform,
  useViewportScroll,
} from 'framer-motion';
import * as React from 'react';

// eslint-disable-next-line react/prop-types
const Parallax = ({ children, offset = 48, className }, ...rest) => {
  const prefersReducedMotion = useReducedMotion();
  const [elementTop, setElementTop] = React.useState(0);
  const [clientHeight, setClientHeight] = React.useState(0);
  const ref = React.useRef(null);

  const { scrollY } = useViewportScroll();

  const initial = elementTop - clientHeight;
  const final = elementTop + offset;

  const yRange = useTransform(scrollY, [initial, final], [offset, -offset]);
  const y = useSpring(yRange, { stiffness: 400, damping: 90 });

  // I think useLayoutEffect is better here, but I was getting an warning about SSR so changed it
  React.useEffect(() => {
    const element = ref.current;
    const onResize = () => {
      setElementTop(
        element.getBoundingClientRect().top + window.scrollY ||
          window.pageYOffset
      );
      setClientHeight(window.innerHeight);
    };
    onResize();
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [ref]);

  // Don't parallax if the user has "reduced motion" enabled
  if (prefersReducedMotion) {
    return <div {...rest}>{children}</div>;
  }

  return (
    <motion.div ref={ref} style={{ y }} className={className} {...rest}>
      {children}
    </motion.div>
  );
};

export { Parallax };
