import * as React from 'react';

import Background from '../images/background.svg';
import { Afterpay } from './vectors';

export function WhyPageComponent() {
  return (
    <article
      id="why"
      className="relative z-10 w-full px-4 py-24 bg-white sm:px-6 lg:px-8"
    >
      <div className="absolute inset-0 hidden md:block">
        <img className="translate-left-50" src={Background} alt="" />
      </div>
      <div className="relative flex flex-wrap w-full max-w-6xl mx-auto">
        <div className="w-full md:w-1/3">
          <h2 className="w-full font-serif text-2xl font-semibold leading-tight uppercase md:p-10">
            <span className="inline-block">Why Choose&nbsp;</span>
            <span className="inline-block">Port Macquarie&nbsp;</span>
            <span className="inline-block">Day Spa?</span>
          </h2>
        </div>
        <div className="w-full md:col-2 md:w-2/3">
          <p className="mt-4 font-semibold md:mt-0">
            Since 2002, Port Day Spa has been your oasis where tranquil
            relaxation meets unforgettable results.
          </p>
          <p>
            Adjacent to the picturesque waters of the Hastings River, Port Day
            Spa transcends guests on a journey of self-love through their
            curated menu of skin, spa and beauty rituals.
          </p>
          <p>
            Whether you’re visiting us for your first or fiftieth time, you’re
            embraced by the heartfelt welcome of our therapists who are thrilled
            to invite you into our calming haven. To us, you’re not just another
            number; you’re a guest who will receive all of our heart, soul, care
            and attention.
          </p>
          <p>
            Our extensive menu is divided into three rituals: skin, spa and
            beauty.
          </p>
          <p>
            As your Ultraceuticals Skin Experts, our clinical-forward{' '}
            <span className="italic font-medium">skin menu</span> supports you
            to revive, condition and correct your skin concerns and deliver
            visible skin results. We harness industry leading cosmeceuticals and
            results-driven technology to craft tailored treatment journeys that
            achieve your individual skin goals at your chosen pace.
          </p>
          <p>
            If relaxation is your priority, our{' '}
            <span className="italic font-medium">spa menu</span> has you
            covered. Our luxurious spa journeys and indulgent body treatments
            are dedicated to helping you to pause, surrender and reconnect. From
            the warm embrace of our body wraps to enjoying a glass of bubbles in
            a grounding milk bath, our spa journeys are truly the quintessential
            Port Day Spa experience.
          </p>
          <p>
            Port Day Spa is also your home to your{' '}
            <span className="italic font-medium">beauty essentials</span>{' '}
            including waxing, tanning, brows, lashes and makeup. We’re your
            tanning specialists to leave your glowing inside and out.
          </p>
          <p>
            Celebrated by our industry for our 5-star client experience, every
            experience delivered at Port Day Spa begins with education. At the
            forefront of everything we do, we continually upskill and evolve our
            expertise to ensure you’re always receiving the industry’s very
            best. We break through the hype of trends and fads to bring you
            treatments that deliver on their promise and celebrate your unique
            complexion. .
          </p>
          <p>
            We know that for many, visiting our day spa means so much more than
            just an appointment. It is time that’s dedicated for just you, it’s
            an escape from the hustle and bustle outside, it’s a moment to
            reconnect with your mind, body and spirit and permission to love
            every inch of yourself exactly as you are.
          </p>
          <p>
            That’s why everything we do, from the experiences on our menu to the
            uplifting scents and sounds that carry throughout your time with us,
            are designed to ignite your self-love and enhance what it means to
            practice self-care.
          </p>
          <p>
            Farewelling the quick-fixes and trends that come and go, we embrace
            a holistic approach to inner and outer wellbeing and deliver a spa
            experience built on listening, personalisation and comfort. We’re
            proud to consider ourselves healers and know that for each of our
            guests this begins from within. This guides our purpose each day and
            is a testament to our award-winning service and expertise.
          </p>
          <p>
            Our treatment rooms are beautifully presented with heated beds,
            tranquil lighting and soothing sounds. Our fresh, Hamptons-inspired
            retreat is perfect for the ultimate{' '}
            <span className="italic font-medium">‘me time’</span> moment or one
            shared with a loved one thanks to our double treatment rooms and
            state-of-the-art relaxation spa zone.
          </p>
          <p>
            All that’s left is for you to step through our doors, farewell the
            outside world for just a moment and surrender to total and utter
            relaxation.{' '}
            <span className="italic font-medium">
              We’ll take care of the rest.
            </span>
          </p>
          <p>
            <Afterpay className="w-1/2 mx-auto" />
          </p>
        </div>
      </div>
    </article>
  );
}
