import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as React from 'react';

import { useGraphQL } from '../hooks';

export function GiftVoucherCopy() {
  const { voucherDesktop, voucherMobile } = useGraphQL();
  return (
    <div id="gift-voucher" className="relative z-10 overflow-hidden bg-white">
      <div className="absolute inset-0 flex items-center justify-center w-full h-full">
        <GatsbyImage
          image={voucherDesktop.childImageSharp.gatsbyImageData}
          className="hidden w-full md:block"
          alt=""
        />
      </div>
      <div className="relative inset-0 flex items-center">
        <article className="relative w-full px-4 py-24 sm:px-6 lg:px-8">
          <div className="flex flex-wrap w-full max-w-6xl mx-auto">
            <div className="w-full md:w-1/3">
              <h2 className="font-serif text-2xl font-semibold leading-tight uppercase">
                Spoil someone <br />
                with a gift <br />
                voucher
              </h2>
              <div className="mt-4">
                <p>
                  Give the most memorable gift of all, allow us to wrap up
                  tranquility and provide your loved one with a beautifully
                  presented gift voucher.
                </p>
                <p>
                  Purchasing your gift voucher has never been easier, you can
                  select from our extensive spa packages, create your own
                  special treatment from our spa menu or simply specify a dollar
                  ($) amount and let them choose.
                </p>
                <p>
                  <Link
                    to="/gift-voucher"
                    className="inline-block px-2 py-1 leading-none uppercase transition-all border border-gray-500 hover:bg-gray-800 hover:text-white hover:border-gray-800"
                  >
                    Buy gift voucher
                  </Link>
                </p>
              </div>
            </div>
            <div className="block w-full mt-8 md:mt-0 md:pl-12 md:hidden md:w-2/3">
              <GatsbyImage
                image={voucherMobile.childImageSharp.gatsbyImageData}
                style={{ transform: `rotate(-10deg)` }}
                className="w-full"
                alt=""
              />
            </div>
          </div>
        </article>
      </div>
    </div>
  );
}
