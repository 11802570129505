import { GatsbyImage } from 'gatsby-plugin-image';
import * as React from 'react';

import { useGraphQL } from '../hooks';
import { ContactForm } from './contact-form';
import { InstagramWidget } from './instagram';
import { Parallax } from './parallax';
import { Facebook, Instagram } from './vectors';

export function ContactHome() {
  const data = useGraphQL();
  return (
    <div className="relative z-10">
      <div className="flex items-center overflow-hidden">
        <div className="flex-1">
          <Parallax>
            <GatsbyImage
              image={data.contactHero.childImageSharp.gatsbyImageData}
              className="md:-my-12"
              alt=""
            />
          </Parallax>
        </div>
      </div>
      <article id="contact" className="relative w-full px-4 py-24 bg-white">
        <div className="flex flex-wrap max-w-6xl mx-auto">
          <div className="flex flex-col flex-1 w-full px-4 md:pt-8 md:w-1/2">
            <h2 className="font-serif text-2xl font-semibold leading-tight uppercase">
              Contact us
            </h2>
            <p>
              Fill out the form below or you can find us at <br />
              <strong>
                <a
                  href={data.site.siteMetadata.googleMapsLink}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {data.site.siteMetadata.address}
                </a>
              </strong>
            </p>
            <p>
              Phone us on{' '}
              <strong>
                <a href={`tel:${data.site.siteMetadata.phone}`}>
                  {data.site.siteMetadata.phoneFormatted}
                </a>
              </strong>{' '}
              or email{' '}
              <strong>
                <a href={`mailto:${data.site.siteMetadata.email}`}>
                  {data.site.siteMetadata.email}
                </a>
              </strong>
            </p>
            <p className="flex flex-wrap items-center mb-auto">
              <a
                className="py-2"
                aria-label="Facebook"
                href={data.site.siteMetadata.facebook}
                rel="noopener noreferrer"
                target="_blank"
              >
                <Facebook className="mr-2 text-2xl fill-current hover:text-gray-600" />{' '}
              </a>
              <a
                className="py-2"
                aria-label="Instagram"
                href={data.site.siteMetadata.instagram}
                rel="noopener noreferrer"
                target="_blank"
              >
                <Instagram className="mr-2 text-2xl fill-current hover:text-gray-600" />
              </a>
              <span className="font-serif text-xl font-semibold leading-none uppercase">
                Follow us on social&nbsp;media
              </span>
            </p>
            <InstagramWidget />
          </div>
          <div className="flex w-full md:w-1/2">
            <ContactForm />
          </div>
        </div>
      </article>
    </div>
  );
}
