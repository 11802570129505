import { Link } from 'gatsby';
import React, { useState } from 'react';
import { FiSearch, FiShoppingCart } from 'react-icons/fi';

import { useCartCount } from '../hooks';
import { SearchModal } from './search-modal';

export function ShopBar() {
  const count = useCartCount();
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <div className="relative flex w-full max-w-screen-xl py-4 pl-10 pr-8 mx-auto border-t border-gray-200 sm:grid sm:grid-cols-8">
      <p className="hidden text-sm text-center text-gray-500 uppercase sm:col-span-6 sm:col-start-2 sm:block">
        Free local delivery
      </p>
      <div className="flex items-center justify-between w-full space-x-2 sm:justify-end">
        <button
          type="button"
          onClick={() => setIsModalOpen((prevState) => !prevState)}
          className="p-2 text-gray-400 focus:outline-none focus:text-gray-600"
        >
          <FiSearch />
          <SearchModal
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
          />
        </button>
        <div className="relative">
          <Link
            to="/cart/"
            className="inline-block p-2 text-gray-400 focus:outline-none focus:text-gray-600"
          >
            <FiShoppingCart />
            {count > 0 && (
              <div className="absolute inset-y-0 right-2 flex items-center justify-center transform translate-x-2.5 -translate-y-2 pointer-events-none">
                <span
                  className={`${
                    count >= 10 ? 'px-2' : 'w-5'
                  } flex items-center justify-center h-5 max-w-xs text-xs font-medium text-white transform translate-x-1/2 bg-gray-900 rounded-full shadow-lg pointer-events-auto focus:outline-none focus:ring`}
                >
                  {count}
                </span>
              </div>
            )}
          </Link>
        </div>
      </div>
    </div>
  );
}
